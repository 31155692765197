import { useRef } from "react";
import { useUploadPhotoMutation } from "../core";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export function MyProfile() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadPhoto] = useUploadPhotoMutation();
  const user = useSelector((state: RootState) => state.user);

  function onUploadPhoto(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      uploadPhoto(file);
    }
  }

  console.log(process);
  return (
    <div>
      <input ref={fileInputRef} type="file" onChange={onUploadPhoto} hidden />
      <button
        className="btn btn-primary"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        Upload Photo
      </button>
      {user && (
        <img
          className="rounded-full h-40 w-40"
          src={`${process.env.OBJECT_STORAGE_ENDPOINT}/profiles/${user.photoUrl}`}
          alt=""
        />
      )}
    </div>
  );
}
