import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Label,
  api,
  useAddLabelsMutation,
  useRemoveLabelsMutation,
  useLazyGetLabelsQuery,
} from "./core";
import { RootState } from "./store";
import { setLabels, setToken, setUser } from "./core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GithubPicker } from "react-color";

export function Main() {
  const [color, setColor] = useState("");
  const [label, setLabel] = useState("");
  const navigate = useNavigate();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const labels = useSelector((state: RootState) => state.labels);
  const dispatch = useDispatch();
  const location = useLocation();

  const [addLabel] = useAddLabelsMutation();
  const [removeLabel] = useRemoveLabelsMutation();
  const [getLabels] = useLazyGetLabelsQuery();

  useEffect(() => {
    async function fetchLabels() {
      if (user) {
        const labels = await getLabels(user?.uuid).unwrap();
        dispatch(setLabels(labels));
      }
    }

    fetchLabels();
  }, []);

  return (
    <div className="drawer drawer-open">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-16">
        <label
          htmlFor="my-drawer"
          className="btn btn-primary drawer-button hidden"
        >
          Open drawer
        </label>
        <Outlet />
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
          <li className="pb-4">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/tasks">Tasks</NavLink>
            <ul
              className={`mt-4 ${
                location.pathname.includes("tasks") ? "" : "hidden"
              }`}
            >
              {!labels?.length
                ? ""
                : labels.map((label: any) => (
                    <Label
                      color={label.color}
                      key={label.uuid}
                      className="bg-red-100 text-white relative rounded-box p-1 pl-4 mb-4"
                    >
                      {label.name}
                      <span
                        onClick={() => {
                          removeLabel({ id: user?.uuid, label: label.uuid });
                        }}
                        className="absolute right-0 -bottom-1 hover:text-black"
                      >
                        x
                      </span>
                    </Label>
                  ))}
            </ul>
            <div
              className={`flex pr-0 ${
                location.pathname.includes("tasks") ? "" : "hidden"
              }`}
            >
              <input
                className="input input-sm w-full"
                placeholder="Add new label..."
                value={label}
                onChange={(event) => {
                  setLabel(event.target.value);
                }}
                type="text"
              />{" "}
              <ColorPickerToggle
                className="h-6 w-8 bg-white rounded"
                color={color}
                onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
              >
                <ColorPickerContent
                  className={`${isColorPickerVisible ? "" : "hidden"}`}
                >
                  <GithubPicker
                    className="hidden"
                    color={color}
                    onChangeComplete={(color) => {
                      setColor(color.hex);
                    }}
                  />
                </ColorPickerContent>
              </ColorPickerToggle>
              <button
                className="btn btn-primary btn-sm"
                onClick={async () => {
                  await addLabel({
                    id: user?.uuid,
                    label: {
                      color: color,
                      name: label,
                    },
                  });
                }}
              >
                Add
              </button>
            </div>
          </li>
          <li className="pb-4">
            <NavLink to="/habits">Habits</NavLink>
          </li>
        </ul>
        <div className="absolute bottom-0 w-full p-8">
          <h2 className="pb-4 text-center">Hi {user ? user.name : ""}</h2>
          <div className="avatar w-full pb-4">
            <div className="w-8 rounded-full mx-auto ring ring-primary ring-offset-base-100 ring-offset-2">
              <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
            </div>
          </div>
          <button
            className="btn btn-primary w-full mb-4"
            onClick={() => {
              navigate("/profile");
            }}
          >
            My Profile
          </button>
          <button
            className="btn btn-primary w-full"
            onClick={() => {
              dispatch(setUser(null));
              dispatch(setToken(""));
              localStorage.removeItem("accessToken");
              localStorage.removeItem("user");
              dispatch(api.util.resetApiState());
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

const Label = styled.li`
  background-color: ${(props) => props.color || "#1273de"};
`;

const ColorPickerToggle = styled.button`
  background-color: ${(props) => props.color || "#1273de"};
  position: relative;
`;

const ColorPickerContent = styled.div`
  position: absolute;
  top: 32px;
  left: -5px;
`;
